 <template>
	<el-dialog top="20vh" title="垫资支付" width="500px" :visible.sync="this_is_show" @close="close">
        		<el-form status-icon label-width="0" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="form.this_payed" placeholder="总运费(不包含服务费)"  auto-complete="new-password"  clearable>
					<el-button style="width:200px" slot="prepend">总运费(不包含服务费)</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="service_charge" placeholder="服务费" auto-complete="new-password" disabled>
					<el-button style="width:100px" slot="prepend">服务费</el-button>
				</el-input>
			</el-form-item>
            <el-form-item>
				<el-select class="el_input" v-model="form.select_target" clearable placeholder="支付类型">
					<el-option label="给司机" value="driver"></el-option>
					<el-option label="给车主" value="truck_owner"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-input v-model="form.this_settled_of_driver" placeholder="给司机" type="number" auto-complete="new-password" v-if="form.select_target=='driver'" @input="amount_edit">
					<el-button style="width:100px" slot="prepend">给司机</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="form.this_settled_of_truck_owner" placeholder="给车主" type="number" auto-complete="new-password"  v-if="form.select_target=='truck_owner'" @input="amount_edit">
					<el-button style="width:100px" slot="prepend">给车主</el-button>
				</el-input>
			</el-form-item>
            <el-form-item>
				<el-input v-model="form.service_add" placeholder="额外服务费" type="number" auto-complete="new-password" @input="service_add_edit">
					<el-button style="width:100px" slot="prepend">额外服务费</el-button>
				</el-input>
			</el-form-item>
            <el-form-item>
                <div class="button">
                     <!-- <el-button @click="dialogFormVisible=false">解除绑定</el-button> -->
                     <el-button type="primary" @click="set_sub_price_confirm">立即提交</el-button>
                </div>
               
           </el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { Loading } from 'element-ui';
	import {mapState} from 'vuex'
	export default {
		props:{
			data:[Object,String],
			is_show:Number,
		},
		components:{
		},
		data() {
			return {
                form:{
                    this_payed:'', //本次支付的总运费（包含服务费）
                    select_target:'driver',//本次支付类型 driver:给司机 truck_owner:给车主
                    this_settled_of_driver:'',//给司机的金额
                    this_settled_of_truck_owner:'',//给车主的金额
					service_add:0,//额外服务费金额
                },
				service_charge:'',//服务费
				//页面是否显示
				this_is_show:false,

                tord_list:null,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				
				}else {
					this.tord_list = this.data
                    this.this_is_show=true
				}
				this.form={
					this_payed:this.data.cost_info.freight_total,
                    select_target:'driver',//本次支付类型 driver:给司机 truck_owner:给车主 默认给司机
                    this_settled_of_driver:this.data.cost_info.give_driver_total,
                    this_settled_of_truck_owner:this.data.cost_info.give_truck_owner_total,//给车主的金额
					service_add:0
				}
				this.service_charge=this.data.cost_info.service_charge//额外服务费金额
			},
		},
		computed:{
			...mapState(["user_info"])
		},
		methods: {
			amount_edit(value){
				if(value <0){
					value = 0
				}
				//给司机
				if(this.form.select_target =='driver'){
					this.form.service_add=this.form.this_payed  - value
				}else{
					this.form.service_add=this.form.this_payed  -value
				}
			},
			//额外服务费修改
			service_add_edit(value){
				if(value <0){
					value = 0
				}
				//给司机
				if(this.form.select_target =='driver'){
					this.form.this_settled_of_driver=this.form.this_payed - value
				}else{
					this.form.this_settled_of_truck_owner=this.form.this_payed  -value
				}
			},
            set_sub_price_confirm(){
                //提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_creat_by_admin',
						truck_tord_num:this.data.truck_tord_num,
						...this.form
					},callback:(data)=>{
                        this.$my.other.msg({
							type:'success',
							str:'支付成功'
					    })
                        this.this_is_show = false
                        // this.$emit("edited");
					}
				})
            },
		   close(){
			    this.form={
                    this_payed:'', //本次支付的总运费（包含服务费）
                    select_target:'',//本次支付类型 driver:给司机 truck_owner:给车主
                    this_settled_of_driver:'',//给司机的金额
                    this_settled_of_truck_owner:'',//给车主的金额
					service_add:'',//额外服务费金额
                }
           },
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		
		border-radius: 50%;
		color:rgb(0, 0, 0);
		.shou{
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: red;
	}
	.green{
		background-color: #4CD964;
	}
    .button{
        text-align: center;
    }
	.el_input{
		width:94%
	}
</style>