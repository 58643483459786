import { render, staticRenderFns } from "./tord_pay.vue?vue&type=template&id=5a040bbd&scoped=true&"
import script from "./tord_pay.vue?vue&type=script&lang=js&"
export * from "./tord_pay.vue?vue&type=script&lang=js&"
import style0 from "./tord_pay.vue?vue&type=style&index=0&id=5a040bbd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a040bbd",
  null
  
)

export default component.exports